<template>
  <div class="error-wrap">
    <!-- pc -->
    <div v-if="!isMobile" class="error-wrap-pc">
      <div class="error-wrap-cont">
        <div class="reason-des-wrap">
          <h3 class="title">Uh Oh！</h3>
          <p class="title-cn">你访问的页面不存在</p>
          <p class="des-reason reason-header">
            没找到你要访问的页面，可能原因：
          </p>
          <p class="des-reason">
            <img src="./assets/images/cr.png" class="circle" alt="" />
            网速过慢或网络中断
          </p>
          <p class="des-reason">
            <img
              class="circle"
              src="./assets/images/cr.png"
              alt=""
            />页面已被删除
          </p>
          <p class="des-reason home-title">你可以尝试刷新页面或回到首页</p>
          <a href="/" target="" class="btn"> 首页 </a>
          <img class="people" src="./assets/images/people.png" alt="" />
        </div>
      </div>
    </div>
    <!-- mobile -->
    <div v-else class="error-wrap-m">
      <h2>你访问的页面不存在</h2>
      <p class="error-des">没找到你要访问的页面</p>
      <div class="error-detail">
        <p>可能原因：</p>
        <p class="error-reason">网速过慢或网络中断</p>
        <p class="error-reason">页面已被删除</p>
        <p>你可以尝试刷新页面或回到首页</p>
      </div>
      <a href="/" class="btn">回到首页</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isMobile: /Mobile|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent),
    };
  },
  created() {
    this.isMobile = /Mobile|Android|iPhone|iPad|iPod|Windows Phone/i.test(navigator.userAgent)
  },
};
</script>

<style lang="less" scoped>
.error-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #fafafa;
}

.error-wrap-pc {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1495px;
  height: 858px;
  background: #fff;
  .error-wrap-cont {
    position: relative;
    width: 1200px;
    height: 700px;
    background: url("./assets/images/bg.png") center no-repeat;
    background-size: cover;
    overflow: hidden;
  }

  .reason-des-wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 145px;
    margin-left: 163px;
  }

  .title {
    font-size: 70px;
    font-weight: 500;
    color: #fff;
  }

  .title-cn {
    height: 50px;
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: 500;
    color: #fff;
  }

  .des-reason {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
  }

  .home-title {
    padding: 0;
    margin: 20px 0 30px;
  }

  .reason-header {
    margin-bottom: 10px;
  }

  .circle {
    width: 4px;
    margin-right: 6px;
  }

  .people {
    position: absolute;
    right: 39px;
    bottom: 0;
    width: 580px;
    height: 594;
  }
  .btn {
    width: 96px;
    height: 34px;
    font-size: 13px;
    font-weight: 500;
    line-height: 34px;
    color: #fff;
    text-align: center;
    background-color: transparent;
    border: 1px solid #fff;
    border-radius: 17px;
  }
}

.error-wrap-m {
  display: flex;
  flex-direction: column;
  align-items: center;

  .error-icon {
    width: 0.79rem;
    height: 0.79rem;
  }

  h2 {
    font-size: 0.4rem;
    font-weight: 500;
    line-height: 0.56rem;
    color: #000;

    padding-bottom: 0.3rem;
    margin: 0.5rem 0 0.3rem;
    border-bottom: 0.01rem solid rgba(0, 0, 0, 0.6);
  }

  .error-des {
    font-size: 0.32rem;
    line-height: 0.45rem;
    color: #000;

    margin-bottom: 0.2rem;
  }

  .error-detail {
    padding-bottom: 0.88rem;

    p {
      margin-bottom: 0.12rem;
      font-size: 0.26rem;
      line-height: 0.37rem;
      opacity: 0.8;

      &:last-of-type {
        width: 3.2rem;
        white-space: pre;
      }
    }

    .error-reason {
      position: relative;
      padding-left: 0.14rem;

      &::before {
        position: absolute;
        top: 0.14rem;
        left: 0;
        width: 0.06rem;
        height: 0.06rem;
        content: "";
        background: #000;
        border-radius: 50%;
      }

      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }

  .btn {
    width: 3.5rem;
    height: 1.1rem;
    padding: 0;
    font-size: 0.3rem;
    line-height: 1.1rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 35px;
  }
}
</style>
